import { useContext, useEffect } from 'react';

import { CircularProgress, Grid } from '@mui/material';

import * as amplitude from '@amplitude/analytics-browser';
import { jwtDecode } from 'jwt-decode';

import { PermissionsContext } from '../PermissionsContext';
import { LinkToken } from '../types/LinkToken';

export default function Confirmation() {
  const { TSP, onboarded, redirectURI, serviceName, token } =
    useContext(PermissionsContext);
  const decodedToken = jwtDecode<LinkToken>(token);
  amplitude.setUserId('service-' + String(decodedToken.sid));
  amplitude.setDeviceId('fleet-code-' + decodedToken.fc);
  const OnboardedDiv = () => (
    <div>{TSP} account linked! You may now close this page.</div>
  );

  const NotOnboardedDiv = () => {
    useEffect(() => {
      amplitude.track('Error Linking', {
        tsp: TSP,
        serviceName: serviceName,
      });
    }, []);

    return <div>Error linking {TSP}.</div>;
  };

  function RedirectingDiv() {
    useEffect(() => {
      setTimeout(() => {
        window.location.replace(redirectURI);
      }, 2000);
    }, []);

    return (
      <>
        <div>{TSP} account linked! Redirecting...</div>
        <CircularProgress />
      </>
    );
  }

  const CheckDiv = () => {
    useEffect(() => {
      amplitude.track('Successfully Linked', {
        tsp: TSP,
        serviceName: serviceName,
      });
    }, []);
    return <>{redirectURI ? <RedirectingDiv /> : <OnboardedDiv />}</>;
  };

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      {onboarded ? <CheckDiv /> : <NotOnboardedDiv />}
    </Grid>
  );
}
