import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';

export default function HOSConnectLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement label='Company ID' type={'text'} name={'database'} />
      </OnboardGridItem>
    </>
  );
}
