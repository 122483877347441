import { useState } from 'react';
import { useFormContext } from 'react-hook-form-mui';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

import * as amplitude from '@amplitude/analytics-browser';

import { OnboardGridItem } from '../OnboardGridItem';
import UserPassLogin from './UserPass';

type SamsaraLoginProps = {
  onDirectConnect: () => void;
  tsp: string;
  serviceName: string;
  onValidityChange?: (isValid: boolean) => void;
};

export default function SamsaraLogin({
  onDirectConnect,
  tsp,
  serviceName,
  onValidityChange,
}: SamsaraLoginProps) {
  const [skipCredentials, setSkipCredentials] = useState(false);
  const [hasUsername, setHasUsername] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const { setValue } = useFormContext();

  const handleSkipCredentials = () => {
    const newSkipValue = !skipCredentials;

    if (newSkipValue) {
      amplitude.track('User Selected Skip Credentials', {
        tsp,
        serviceName,
      });

      // Update form state
      setValue('skipCredentials', true);
      setValue('username', '');
      setValue('password', '');

      // Update local state
      setHasUsername(false);
      setHasPassword(false);
      setSkipCredentials(true);
      onValidityChange?.(true); // Form is valid when skipping credentials
    } else {
      setValue('skipCredentials', false);
      setSkipCredentials(false);
      onValidityChange?.(hasUsername && hasPassword); // Form is valid only if both fields are filled
    }
  };

  const handleFieldChange = (field: 'username' | 'password', value: string) => {
    const newHasUsername = field === 'username' ? !!value : hasUsername;
    const newHasPassword = field === 'password' ? !!value : hasPassword;

    if (field === 'username') {
      setHasUsername(!!value);
    } else {
      setHasPassword(!!value);
    }

    // Update form validity - valid if skipping credentials OR both fields are filled
    const isValid = skipCredentials || (newHasUsername && newHasPassword);
    onValidityChange?.(isValid);
  };

  return (
    <>
      {/* First section - Username/Password login */}
      <OnboardGridItem>
        <UserPassLogin
          disabled={skipCredentials}
          onUsernameChange={(value) => handleFieldChange('username', value)}
          onPasswordChange={(value) => handleFieldChange('password', value)}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <Typography variant='body2' sx={{ mb: 2 }}>
          For your convenience, we can securely store your Samsara credentials.
          This way, you won't have to re-enter them unless something changes,
          like a new password or a Samsara update. If you prefer not to save
          them, you can always re-authorize when needed.
        </Typography>
      </OnboardGridItem>
      <OnboardGridItem>
        <Box display='flex' justifyContent='center' width='100%'>
          <FormControlLabel
            control={
              <Checkbox
                checked={skipCredentials}
                onChange={handleSkipCredentials}
              />
            }
            label="No thanks, I'll re-authorize when needed."
            sx={{ mt: 2 }}
          />
        </Box>
      </OnboardGridItem>
    </>
  );
}
