import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';

export default function SelectiveDrive() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement
          label='UUID'
          type={'text'}
          name={'username'}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='Password'
          type={'password'}
          name={'password'}
          required
        />
      </OnboardGridItem>
    </>
  );
}
