import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import * as amplitude from '@amplitude/analytics-browser';
import { jwtDecode } from 'jwt-decode';

import { PermissionsContext } from '../PermissionsContext';
import {
  ButtonExitLink,
  ExitButtonTSPPageStyles,
  LinkPage,
} from '../components/ButtonExitLink';
import { tsps } from '../tsps';
import { LinkToken } from '../types/LinkToken';
import { checkValid } from '../utils/checkValid';

export default function SelectTSP() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchFilter, setSearchFilter] = useState('');
  const {
    approved,
    token,
    setTSP,
    existingTSPs,
    setUpdatingTSP,
    redirectURIError,
    serviceName,
  } = useContext(PermissionsContext);

  useEffect(() => {
    if (!checkValid(token)) {
      amplitude.track('Invalid Token Error', {
        page: 'SelectTSP',
        serviceName: serviceName,
        errorType: 'invalid_token',
      });

      navigate('/error', {
        state: {
          errorMsg: 'Onboarding link is invalid.',
          errorCaption: 'Please try to onboard again.',
        },
      });
    }

    const decodedToken = jwtDecode<LinkToken>(token);
    amplitude.setUserId('service-' + String(decodedToken.sid));
    amplitude.setDeviceId('fleet-code-' + decodedToken.fc);

    // Track initial page load
    amplitude.track('TSP Selection Page View', {
      serviceName: serviceName,
      hasExistingTSPs: existingTSPs.length > 0,
      existingTSPCount: existingTSPs.length,
      timestamp: new Date().toISOString(),
    });

    if (!approved) {
      amplitude.track('Authorization Error', {
        page: 'SelectTSP',
        serviceName: serviceName,
        errorType: 'not_approved',
      });

      navigate({
        pathname: '/',
        search: search,
      });
    }
  });

  const listOfTSPs = tsps
    .filter(function (el) {
      if (searchFilter !== '') {
        return el.key.includes(searchFilter) || el.name.includes(searchFilter);
      } else {
        return true;
      }
    })
    .map((item) => (
      <Grid
        item
        key={item.key}
        xs={6}
        onClick={() => {
          const sessionId = amplitude.getSessionId();
          const timeSpentOnPage = sessionId
            ? Date.now() - new Date(sessionId).getTime()
            : 0;

          amplitude.track('User Selected TSP', {
            tsp: item.key,
            tspName: item.name,
            serviceName: serviceName,
            isUpdatingExisting: existingTSPs.includes(item.key),
            searchWasUsed: searchFilter !== '',
            searchTerm: searchFilter || undefined,
            timeSpentOnPage: timeSpentOnPage,
            timestamp: new Date().toISOString(),
          });

          setUpdatingTSP(existingTSPs.includes(item.key));
          setTSP(item.key);
          navigate({
            pathname: `/onboard`,
            search: search,
          });
        }}
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#fafafa',
          },
        }}
      >
        <Grid
          container
          alignItems={'center'}
          direction='column'
          padding={3}
          sx={{ minHeight: '15vh' }}
        >
          <Grid item>
            <img
              src={`/images/${item.name}.png?w=248&fit=crop&auto=format`}
              alt={item.name}
              loading='lazy'
              width={'75vh'}
              height={'75vh'}
            />
          </Grid>
          <Grid item>
            <Typography textAlign={'center'}>{item.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    ));

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      height={'100%'}
    >
      <Grid
        container
        width={'100%'}
        marginBottom={'15px'}
        justifyContent={'center'}
        position={'relative'}
      >
        <Typography
          variant='h5'
          align='center'
          sx={{
            paddingX: redirectURIError ? 6 : 0,
          }}
        >
          Select Your Telematics Provider
        </Typography>
        {redirectURIError ? (
          <ButtonExitLink
            token={token}
            redirectURIError={redirectURIError}
            serviceName={serviceName}
            pageOnExit={LinkPage.SelectTSP}
            styles={ExitButtonTSPPageStyles}
          />
        ) : null}
      </Grid>
      <Grid item width={'100%'}>
        <TextField
          id='search-input'
          label='Search'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            const searchValue = e.target.value;
            setSearchFilter(searchValue);

            if (searchValue) {
              amplitude.track('TSP Search', {
                searchTerm: searchValue,
                serviceName: serviceName,
                resultCount: tsps.filter(
                  (el) =>
                    el.key.includes(searchValue) ||
                    el.name.includes(searchValue)
                ).length,
                timestamp: new Date().toISOString(),
              });
            }
          }}
          fullWidth
        />
      </Grid>
      <Grid
        item
        justifySelf='center'
        alignSelf='center'
        style={{
          minHeight: '55vh',
          maxHeight: '55vh',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Grid2 spacing={2} container width={'100%'} sx={{ margin: 0 }}>
          {listOfTSPs}
        </Grid2>
      </Grid>
    </Grid>
  );
}
