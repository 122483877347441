import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import * as amplitude from '@amplitude/analytics-browser';

import { PermissionsContext } from '../PermissionsContext';
import axios from '../axios';
import { HelpButtonOption } from './HelpButtonOption';

type ButtonOption =
  | "I can't find my telematics device"
  | "I don't want to share my data"
  | 'I found a bug or issue';

export const HelpButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedButton, setSelectedButton] = useState<ButtonOption | null>(
    null
  );
  const [userInput, setUserInput] = useState<string>('');
  const navigate = useNavigate();
  const { token, TSP, serviceName } = useContext(PermissionsContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    amplitude.track('Help Button Clicked', {
      tsp: TSP,
      serviceName: serviceName,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: ButtonOption) => {
    amplitude.track('Help option clicked', {
      option: option,
      serviceName: serviceName,
    });
    setSelectedButton(option);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(event.target.value);
  };

  const handleBack = () => {
    setSelectedButton(null);
    setUserInput('');
  };

  const handleSkip = () => {
    const errorMessage =
      userInput.length > 0 ? userInput : 'No additional comments';
    const buttonPressed = selectedButton;

    axios
      .post(
        '/service/onboard',
        {
          HelpErrorMsg: errorMessage,
          HelpButtonMsg: buttonPressed,
          TSP: 'HelpErrorSkipped',
        },
        {
          headers: { Authorization: token ? 'Bearer ' + token : '' },
        }
      )
      .then((response) => {
        console.log('Response:', response);
        navigate('/cancel');
        handleClose();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          console.error('Error onboarding:', error.response.data);
          navigate('/cancel');
          handleClose();
        } else {
          console.error('Error:', error);
          navigate('/cancel');
          handleClose();
        }
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'help-popover' : undefined;

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        zIndez: 9999,
      }}
    >
      <Tooltip title='Help'>
        <IconButton sx={{ color: '#FE9659' }} onClick={handleClick}>
          <HelpIcon fontSize='large' />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#E50C45',
            color: '#fff',
            padding: 2,
          }}
        >
          <Typography variant='h6' component='div'>
            Need Help?
          </Typography>
        </Box>
        {selectedButton === null && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 1,
            }}
          >
            <HelpButtonOption
              text="I can't find my telematics device"
              onClick={() =>
                handleOptionClick("I can't find my telematics device")
              }
            />
            <HelpButtonOption
              text="I don't want to share my data"
              onClick={() => handleOptionClick("I don't want to share my data")}
            />
            <HelpButtonOption
              text='I found a bug or issue'
              onClick={() => handleOptionClick('I found a bug or issue')}
            />
          </Box>
        )}
        {selectedButton !== null && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 1,
                border: '0.5px solid',
                borderColor: 'divider',
              }}
            >
              <IconButton color='primary' onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <Typography
                fontSize='16'
                component='div'
                sx={{ marginLeft: 1, marginRight: 2 }}
              >
                {selectedButton}
              </Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
              <TextField
                label={'Anything to add?'}
                multiline
                rows={4}
                variant='outlined'
                value={userInput}
                onChange={handleInputChange}
                inputProps={{ maxLength: 2000 }}
                helperText={`${2000 - userInput.length} characters left`}
                sx={{ width: '100%' }}
                InputProps={{ style: { padding: '12px' } }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 2,
                }}
              >
                <Button color='primary' onClick={handleSkip}>
                  Skip linking telematics
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Popover>
    </Box>
  );
};
