import { Grid, GridProps } from '@mui/material';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const OnboardGridItem = styled(Grid)<GridProps>(() => ({
  paddingBottom: '8px',
  width: '100%',
  '& label.Mui-focused': {
    color: red[500],
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: red[500],
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: red[500],
    },
    '&.Mui-focused fieldset': {
      borderColor: red[500],
    },
  },
}));
