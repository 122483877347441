import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';
import UserPassLogin from './UserPass';

export default function AscendLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement
          label='DOT Number (optional)'
          type={'number'}
          name={'dotnumber'}
        />
      </OnboardGridItem>
    </>
  );
}
