import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';
import APIKeyLogin from './APIKeyLogin';

export default function LytxLogin() {
  return (
    <>
      <APIKeyLogin />
      <OnboardGridItem>
        <TextFieldElement
          label='Pod (e.g. "5")'
          type={'text'}
          name={'database'}
          required
        />
      </OnboardGridItem>
    </>
  );
}
