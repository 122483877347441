import * as amplitude from '@amplitude/analytics-browser';
import { AxiosError } from 'axios';

import axios from '../axios';
import { LoginFormData } from '../types/LoginFormData';
import { motiveRedirectURI } from '../utils/redirect';

interface AuthRouterProps {
  TSP: string;
  serviceName: string;
  token: string;
  fleetCode: string;
  updatingTSP: boolean;
  serviceID: string;
  magicLink: boolean;
  redirectURISuccess?: string;
  clientIDs: {
    motiveClientID?: string;
    samsaraClientID?: string;
    zubieClientID?: string;
  };
  onSuccess: () => void;
  onError: (message: string) => void;
  setRedirectURI: (uri: string) => void;
}

type ApiError = AxiosError<string>;

export class AuthRouter {
  private readonly isOAuthTSP: boolean;
  private readonly linkState: string;

  constructor(private props: AuthRouterProps) {
    const { TSP, token, updatingTSP, magicLink, serviceID, fleetCode } = props;
    this.isOAuthTSP = ['motive', 'samsara', 'zubie'].includes(TSP);
    this.linkState = `${token},${updatingTSP},${magicLink},${serviceID},${fleetCode}`;
  }

  async handleFormSubmit(data: LoginFormData) {
    amplitude.track('Onboarding Form Submit', {
      tsp: this.props.TSP,
      serviceName: this.props.serviceName,
      hasDOTNumber: !!data.dotnumber,
      hasCarrierName: !!data.carriername,
      isUpdatingTSP: this.props.updatingTSP,
      skipCredentials: !!data.skipCredentials,
    });

    try {
      if (this.isOAuthTSP) {
        return await this.handleOAuthSubmit(data);
      }
      return await this.handleStandardSubmit(data);
    } catch (err) {
      const error = err as ApiError;
      this.props.onError(error.response?.data || 'Unknown error occurred');
      throw error;
    }
  }

  private async handleOAuthSubmit(data: LoginFormData) {
    if (data.skipCredentials) {
      return this.redirectToOAuth();
    }

    try {
      if (data.username) {
        sessionStorage.setItem(
          `${this.props.TSP.toLowerCase()}_username`,
          data.username
        );
      }
      if (data.password) {
        sessionStorage.setItem(
          `${this.props.TSP.toLowerCase()}_password`,
          data.password
        );
      }
    } catch (error) {
      // Continue with OAuth flow even if sessionStorage fails
    }

    return this.redirectToOAuth();
  }

  private async handleStandardSubmit(data: LoginFormData) {
    const response = await this.saveCredentials(data);

    if (response.status === 201) {
      amplitude.track('Onboarding Success', {
        tsp: this.props.TSP,
        serviceName: this.props.serviceName,
        isUpdatingTSP: this.props.updatingTSP,
      });

      if (this.props.redirectURISuccess) {
        this.props.setRedirectURI(this.props.redirectURISuccess);
      } else if (this.props.magicLink) {
        this.props.setRedirectURI(process.env.REACT_APP_DEV_DASH || '');
      } else {
        this.props.setRedirectURI(response.data['RedirectURI']);
      }

      this.props.onSuccess();
      return response.data.RedirectURI;
    } else {
      throw new Error(`Unexpected response: ${response.status}`);
    }
  }

  private async saveCredentials(data: LoginFormData) {
    const { token, fleetCode, TSP, updatingTSP } = this.props;

    const response = await axios.post(
      '/service/onboard',
      {
        Name: fleetCode,
        CarrierName: data.carriername,
        DOTNumber: data.dotnumber,
        Username: data.username,
        Password: data.password,
        Database: data.database,
        Apikey: data.apikey,
        AuthorizationCode: data.authorizationcode,
        TSP: TSP,
        FTPUsername: data.FTPUsername,
        FTPPassword: data.FTPPassword,
        UpdateTSPConfirmed: updatingTSP ? true : false,
      },
      {
        headers: { Authorization: token ? 'Bearer ' + token : '' },
      }
    );

    return response;
  }

  private redirectToOAuth() {
    const { TSP, clientIDs, serviceName } = this.props;

    switch (TSP) {
      case 'motive':
        if (!clientIDs.motiveClientID) {
          throw new Error(
            'No Motive app is associated with this account - please contact Axle support'
          );
        }
        return this.handleMotiveRedirect(clientIDs.motiveClientID, serviceName);
      case 'samsara':
        if (!clientIDs.samsaraClientID) {
          throw new Error(
            'No Samsara app is associated with this account - please contact Axle support'
          );
        }
        return this.handleSamsaraRedirect(clientIDs.samsaraClientID);
      case 'zubie':
        if (!clientIDs.zubieClientID) {
          throw new Error(
            'No Zubie app is associated with this account - please contact Axle support'
          );
        }
        return this.handleZubieRedirect(clientIDs.zubieClientID);
      default:
        throw new Error(`Unexpected OAuth TSP: ${TSP}`);
    }
  }

  private handleMotiveRedirect(clientID: string, serviceName: string) {
    const scope = this.getMotiveScope(serviceName);
    const redirectUri = motiveRedirectURI(this.linkState);
    const oauthUrl = `https://api.gomotive.com/oauth/authorize?client_id=${clientID}&response_type=code&scope=${scope}&redirect_uri=${redirectUri}`;
    window.location.replace(oauthUrl);
  }

  private handleSamsaraRedirect(clientID: string) {
    window.location.replace(
      `https://api.samsara.com/oauth2/authorize?client_id=${clientID}&response_type=code&state=${this.linkState}`
    );
  }

  private handleZubieRedirect(clientID: string) {
    window.location.replace(
      `https://login.zubiecar.com/authorize?client_id=${clientID}&response_type=code&state=${this.linkState}`
    );
  }

  private getMotiveScope(serviceName: string): string {
    if (serviceName.includes('AtoB')) {
      return 'companies.read+users.read+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+vehicles.read+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+assets.read+locations.asset_locations+fuel_purchases.manage+ifta_reports.summary';
    }
    return 'eld_devices.read+fault_codes.read+driver_performance_events.read+speeding_events.read+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+ifta_reports.trips+ifta_reports.summary+utilization.driver_utilization+utilization.vehicle_utilization+utilization.idle_events+companies.read+locations.asset_locations+driving_periods.read+driver_details.read+collision_report.read+motive_card_transactions.read+users.manage+vehicles.manage+messages.manage+fuel_purchases.manage+inspection_reports.read+documents.read+assets.manage+hos_logs.manage+motive_card_cards.read';
  }
}
