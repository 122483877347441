import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';
import UserPassLogin from './UserPass';

export default function EzLogzLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement
          label='External Key'
          type={'text'}
          name={'apikey'}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='External App ID'
          type={'text'}
          name={'database'}
          required
        />
      </OnboardGridItem>
    </>
  );
}
