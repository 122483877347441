import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';

import * as amplitude from '@amplitude/analytics-browser';
import { jwtDecode } from 'jwt-decode';

import { PermissionsContext } from '../PermissionsContext';
import axios from '../axios';
import { LinkToken } from '../types/LinkToken';
import { motiveRedirectURI } from '../utils/redirect';
import { LinkPage, handleLeaveLink } from './ButtonExitLink';

export default function OAuthRedirect({ TSP }: { TSP: string }) {
  const {
    onboarded,
    redirectURI,
    setOnboarded,
    setRedirectURI,
    redirectURISuccess,
    serviceName,
  } = useContext(PermissionsContext);
  let [error, setError] = useState(false);
  const { search } = useLocation();
  const hasCalledAPI = useRef(false);

  // API key from Motive, Samsara, or Zubie that Mercury can use to talk to the TSP.
  const APIKey = new URLSearchParams(search).get('code');

  // Our own link state that we encoded previously (see Onboard.tsx).
  const linkState = new URLSearchParams(search).get('state');

  // NOTE: token, serviceID, and fleetCode could be the literal string 'null'
  const [token, updatingTSP, magicLink, serviceID, fleetCode] =
    linkState!.split(',');

  const decodedToken = jwtDecode<LinkToken>(token);
  amplitude.setUserId('service-' + String(decodedToken.sid));
  amplitude.setDeviceId('fleet-code-' + decodedToken.fc);

  const onboardedDiv = (
    <div>{TSP} account linked! You can now close this page.</div>
  );

  const errorMsg = `Error occurred linking ${TSP}.`;

  const unonboardedDiv = <div>Linking...</div>;

  function CheckDiv() {
    amplitude.track('Successfully Linked', {
      tsp: TSP,
      serviceName: serviceName,
    });
    amplitude.flush();
    return <>{redirectURI ? <RedirectingDiv /> : onboardedDiv}</>;
  }

  function RedirectingDiv() {
    window.location.replace(redirectURI);
    return (
      <>
        <div>{TSP} account linked! Redirecting...</div>
      </>
    );
  }

  useEffect(() => {
    if (hasCalledAPI.current) {
      return;
    }

    hasCalledAPI.current = true;

    // Track OAuth redirect page load with initial parameters
    amplitude.track('OAuth Redirect Page Load', {
      tsp: TSP,
      serviceName: serviceName,
      hasAPIKey: !!APIKey,
      hasLinkState: !!linkState,
      isUpdatingTSP: updatingTSP === 'true',
      isMagicLink: magicLink === 'true',
    });

    // Retrieve stored credentials from sessionStorage
    let storedUsername = '';
    let storedPassword = '';

    try {
      storedUsername =
        sessionStorage.getItem(`${TSP.toLowerCase()}_username`) || '';
      storedPassword =
        sessionStorage.getItem(`${TSP.toLowerCase()}_password`) || '';
    } catch (error) {
      // Continue with the flow even if sessionStorage fails
    }

    axios
      .post(
        '/service/onboard',
        {
          APIKey: APIKey,
          TSP: TSP.toLowerCase(),
          MotiveRedirectURI:
            TSP.toLowerCase() === 'motive' ? motiveRedirectURI(linkState!) : '',
          UpdateTSPConfirmed: updatingTSP === 'true',
          // Include stored credentials if available
          Username: storedUsername,
          Password: storedPassword,
        },
        {
          headers: {
            Authorization: token === 'null' ? '' : 'Bearer ' + token,
          },
        }
      )
      .then((res) => {
        // Clear stored credentials for security
        try {
          sessionStorage.removeItem(`${TSP.toLowerCase()}_username`);
          sessionStorage.removeItem(`${TSP.toLowerCase()}_password`);
          console.log(`[OAuthRedirect] Cleared stored credentials for ${TSP}`);
        } catch (error) {
          console.error(
            `[OAuthRedirect] Error clearing credentials from sessionStorage:`,
            error
          );
          // Continue with the flow even if sessionStorage fails
        }

        if (res.status === 201) {
          // Track successful API response with details
          amplitude.track('TSP API Response Success', {
            tsp: TSP,
            serviceName: serviceName,
            responseStatus: res.status,
            hasRedirectURI: !!res.data['RedirectURI'],
            hasSuccessURI: !!(redirectURISuccess || decodedToken?.urisuccess),
            isMagicLink: magicLink === 'true',
          });

          // Success-specific URI should override default RedirectURI when it exists
          if (redirectURISuccess || decodedToken?.urisuccess) {
            const redirectSuccess =
              redirectURISuccess !== ''
                ? redirectURISuccess
                : decodedToken.urisuccess;
            setRedirectURI(redirectSuccess);
          } else if (magicLink === 'true') {
            setRedirectURI(process.env.REACT_APP_DEV_DASH);
          } else {
            setRedirectURI(res.data['RedirectURI']);
          }
          setOnboarded(true);

          // Track final success state
          amplitude.track('Successfully Linked', {
            tsp: TSP,
            serviceName: serviceName,
            redirectType: redirectURISuccess
              ? 'success_uri'
              : decodedToken?.urisuccess
                ? 'token_success_uri'
                : magicLink === 'true'
                  ? 'magic_link'
                  : 'default',
            fleetCode: decodedToken.fc,
            serviceId: decodedToken.sid,
          });
        }
      })
      .catch((err) => {
        // Track detailed error information
        amplitude.track('Error Linking', {
          tsp: TSP,
          serviceName: serviceName,
          errorStatus: err.response?.status,
          errorMessage: err.response?.data?.message || err.message,
          errorCode: err.response?.data?.code,
          hasAPIKey: !!APIKey,
          hasLinkState: !!linkState,
          isUpdatingTSP: updatingTSP === 'true',
        });
        console.log('Error! Onboarding rejected:', err);
        setError(true);
      });
  }, [
    APIKey,
    fleetCode,
    linkState,
    magicLink,
    serviceID,
    setOnboarded,
    setRedirectURI,
    token,
    decodedToken,
    TSP,
    updatingTSP,
    serviceName,
    redirectURISuccess,
  ]);

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <img
          src='/images/truck.png'
          alt='truck'
          loading='lazy'
          width={'250px'}
          height={'125px'}
        />
      </Grid>
      <Grid
        container
        item
        justifyContent='center'
        alignItems='center'
        direction={'column'}
      >
        <Grid item>
          <Typography variant='h5' align={'center'}>
            <b>
              {error ? errorMsg : onboarded ? <CheckDiv /> : unonboardedDiv}
            </b>
          </Typography>
        </Grid>
        {error ? (
          <Grid item>
            <Typography variant='caption' align={'center'}>
              Please try to onboard again.
            </Typography>
          </Grid>
        ) : null}
        {error && decodedToken.urierror ? (
          <Grid item>
            <Button
              onClick={() =>
                handleLeaveLink({
                  token: token,
                  redirectURIError: decodedToken.urierror,
                  serviceName: serviceName,
                  pageOnExit: LinkPage.Motive,
                  selectedTSP: TSP,
                })
              }
              sx={{
                marginTop: 4,
                color: 'black',
              }}
            >
              <ArrowBackIcon
                sx={{
                  marginRight: 1,
                }}
              />
              {decodedToken.sid === 320 ? 'Go back to AtoB' : 'Go back'}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
