import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';
import UserPassLogin from './UserPass';

export default function SwitchboardLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement label='API ID*' type={'text'} name={'apikey'} />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='API Key*'
          type={'text'}
          name={'authorizationcode'}
        />
      </OnboardGridItem>
      <UserPassLogin />
    </>
  );
}
