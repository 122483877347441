import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';

export default function TTLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement
          label='API Key'
          type={'text'}
          name={'apikey'}
          required
          validation={{
            validate: (value) =>
              value.trim() !== '' ||
              'API Key cannot be empty or only whitespace',
          }}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='Provider token'
          type={'text'}
          name={'authorizationcode'}
          required
          validation={{
            validate: (value) =>
              value.trim() !== '' ||
              'Provider token cannot be empty or only whitespace',
          }}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='DOT Number'
          type={'number'}
          name={'dotnumber'}
          required
        />
      </OnboardGridItem>
    </>
  );
}
