export const tsps = [
  {
    key: 'motive',
    name: 'Motive',
    helpText: (
      <>
        <p>
          Motive uses OAuth for secure access to your telematics data. You can
          connect by providing your Motive admin credentials (i.e., your
          username and password). You have two options:
        </p>
        <ol>
          <li>
            Save your credentials (recommended):
            <ul>
              <li>Enter your Motive admin username and password</li>
              <li>
                We’ll store them securely so we can reconnect automatically
              </li>
            </ul>
          </li>
          <li>
            Skip saving credentials:
            <ul>
              <li>Check “No thanks, I’ll re-authorize when needed”</li>
              <li>
                You’ll manually re-authorize if the connection expires or needs
                updating
              </li>
            </ul>
          </li>
        </ol>
        <p>
          In both cases, you’ll be redirected to Motive’s OAuth flow to grant
          account access. If you need help finding your credentials, use the
          resources linked above.
        </p>
      </>
    ),
  },
  {
    key: 'samsara',
    name: 'Samsara',
    helpText: (
      <>
        <p>
          Samsara uses OAuth for secure access to your telematics data. You can
          connect by providing your Samsara admin credentials (i.e., your
          username and password). You have two options:
        </p>
        <ol>
          <li>
            Save your credentials (recommended):
            <ul>
              <li>Enter your Samsara admin username and password</li>
              <li>
                We’ll store them securely so we can reconnect automatically
              </li>
            </ul>
          </li>
          <li>
            Skip saving credentials:
            <ul>
              <li>Check “No thanks, I’ll re-authorize when needed”</li>
              <li>
                You’ll manually re-authorize if the connection expires or needs
                updating
              </li>
            </ul>
          </li>
        </ol>
        <p>
          In both cases, you’ll be redirected to Samsara’s OAuth flow to grant
          access to your account. If you need help finding your credentials, use
          the resources linked above.
        </p>
      </>
    ),
  },
  {
    key: 'verizon',
    name: 'Verizon Connect',
    helpText:
      'Input the credentials you use to log into the Verizon Reveal Web App.',
  },
  {
    key: 'linxup',
    name: 'Linxup',
    helpText: 'Input the credentials you use to log into the Linxup Web App.',
  },
  {
    key: 'ada',
    name: 'ADA',
    helpText: 'Please request an API Key ADA support. Then input it here.',
  },
  {
    key: 'apollo',
    name: 'Apollo',
    helpText: (
      <ol>
        <li>In the manage view, click the Details icon (person with a gear)</li>
        <li>Under "API Key", copy the code.</li>
        <li>
          If there is no code, click "Generate" and then copy the generated
          code.
        </li>
        <li>Input that code here.</li>
      </ol>
    ),
  },
  {
    key: 'ascend',
    name: 'Ascend',
    helpText: 'Input the credentials you use to log into the Ascend web app.',
  },
  {
    key: 'azuga',
    name: 'Azuga',
    helpText: 'Input the credentials you use to log into the Azuga Web App.',
  },
  {
    key: 'bigroad',
    name: 'BigRoad',
    helpText: (
      <>
        <p>
          Follow the instructions{' '}
          <a
            href='https://app.bigroad.com/manage-api-key'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>{' '}
          to generate a new API key. Then, input that key here.
        </p>
      </>
    ),
  },
  {
    key: 'blueinktech',
    name: 'Blue Ink Tech',
    helpText: (
      <>
        <p>
          Follow the instructions{' '}
          <a
            href='https://knowledge.blueinktech.com/en/does-blue-ink-tech-offer-api-integration'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>{' '}
          to generate a new API key. Then, input that key here.
        </p>
      </>
    ),
  },
  {
    key: 'budgetgps',
    name: 'BudgetGPS',
    helpText: 'Please request an API key from your BudgetGPS representative.',
  },
  {
    key: 'cyntrx',
    name: 'CyntrX',
    helpText: 'Input the credentials you use to log into the CyntrX web app.',
  },
  {
    key: 'drivertech',
    name: 'DriverTech',
    helpText:
      'Please request API credentials from your DriverTech representative.',
  },
  {
    key: 'eldmandate',
    name: 'ELD Mandate',
    helpText: 'Please request an API key from your ELD Mandate representative.',
  },
  {
    key: 'eroad',
    name: 'EROAD',
    helpText: (
      <>
        <p>
          Follow the instructions{' '}
          <a
            href='https://help.eroad.com/us/us-apideveloperresources/us-eroadapi/us-apikey/'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>{' '}
          to generate a new API key. Then, input that key here.
        </p>
      </>
    ),
  },
  {
    key: 'ezlogz',
    name: 'EZLOGZ',
    helpText: (
      <>
        <p>
          Get the "External Key" and "External App Id" from your Ezlogz
          dashboard settings:
        </p>
        <ol>
          <li>Log into the safety dashboard</li>
          <li>Go to Settings and open the "Fleet Settings" tab</li>
          <li>On that page you will find External Fleet Keys</li>
          <li>If empty, click the "Regenerate" button</li>
        </ol>
      </>
    ),
  },
  {
    key: 'forwardthinking',
    name: 'Forward Thinking',
    helpText:
      'Please request API credentials from your Forward Thinking representative.',
  },
  {
    key: 'fleetcomplete',
    name: 'Fleet Complete Web',
    helpText: (
      <ol>
        <li>
          Note: If you have transitioned to Fleet Complete Hub, please choose
          that option.
        </li>
        <li>
          Log into your Fleet Complete account at{' '}
          <a target='_self' href='https://web.fleetcomplete.com/login'>
            https://web.fleetcomplete.com/login
          </a>
          .
          <ol>
            <li>
              Go to the Settings section and click on the "My Profile"
              icon.{' '}
            </li>
            <li>
              Under "General", it will show the username under "Name" and the
              Client ID under "Client ID" that are needed in order to connect
              your Fleet Complete account.
            </li>
          </ol>
        </li>
        <li>Input your Fleet Complete username, password, and Client ID. </li>
      </ol>
    ),
  },
  {
    key: 'fleetcompletehub',
    name: 'Fleet Complete Hub',
    helpText:
      'Note: If you have not transitioned to Fleet Complete Hub, please choose Fleet Complete Web. ' +
      'Input the username and password you use to log into Fleet Complete Hub.',
  },
  {
    key: 'fleethunt',
    name: 'FleetHunt',
    helpText: (
      <ol>
        <li>
          Log into the FleetHunt dashboard at{' '}
          <a target='_self' href='https://app.fleethunt.ca/login'>
            https://app.fleethunt.ca/login
          </a>
          .
          <ol>
            <li>Hover over "Manage" and click on "API Keys"</li>
            <li>Click on "Generate API Key" and name the key "Axle"</li>
            <li>Copy the key under the column "API Key".</li>
          </ol>
        </li>
        <li>Input the copied API Key here.</li>
      </ol>
    ),
  },
  {
    key: 'fleetsharp',
    name: 'FleetSharp',
    helpText:
      'Input the credentials you use to log into the FleetSharp Web App.',
  },
  {
    key: 'fleetup',
    name: 'FleetUp',
    helpText:
      'Input the App ID and App Secret you were provided when your account was created.',
  },
  {
    key: 'fm',
    name: 'FM',
    helpText: 'Please request an API key from your FM ELD representative.',
  },
  {
    key: 'geotab',
    name: 'Geotab',
    helpText:
      'Input the credentials and database you use to log into the Geotab Web App.',
  },
  {
    key: 'gpsinsight',
    name: 'GPS Insight',
    helpText:
      'Input the credentials you use to log into the GPS Insight web app.',
  },
  {
    key: 'gpstab',
    name: 'GPSTab',
    helpText: 'Request an API key from your GPSTab representative.',
  },
  {
    key: 'gpstrackit',
    name: 'GPS Trackit',
    helpText:
      'Input the credentials you use to log into the GPS Trackit Web App.',
  },
  {
    key: 'hcss',
    name: 'HCSS',
    helpText: (
      <>
        <p>
          Follow the instructions at{' '}
          <a
            href='https://developer.hcssapps.com/getting-started/get-credentials/'
            target={'_blank'}
            rel='noreferrer'
          >
            here
          </a>{' '}
          to generate the client credentials.
        </p>
        <ol>
          <li>
            In the HCSS Credentials Portal{' '}
            <a
              href='https://hcssapps.com/ApiAdmin/RequestApiCredentials'
              target={'_blank'}
              rel='noreferrer'
            >
              here
            </a>
            , go to the Product Setup &gt; API &gt; Client ID and Secret.
          </li>
          <li>
            Click on Add Client, select the users and telematics read permission
            scopes, and then click Generate Client.
          </li>
          <li>
            Copy the generated client ID and secret here. You will not be able
            to see these again, so please copy them carefully.
          </li>
        </ol>
      </>
    ),
  },
  {
    key: 'hutch',
    name: 'Hutch',
    helpText: 'Please request an API key from your Hutch representative.',
  },
  {
    key: 'hosconnect',
    name: 'HOS 247',
    helpText:
      "Please input the company ID from your HOS247 profile. Include the word 'Company:', if provided.",
  },
  {
    key: 'intellishift',
    name: 'IntelliShift',
    helpText:
      'Please request an API username and password from IntelliShift support. Then input them here.',
  },
  {
    key: 'isaac',
    name: 'ISAAC',
    helpText:
      'Request an Authorization Code from your ISAAC representative. ' +
      'Then, simply input the credentials you use to log into the ISAAC Web App and authorization code.',
  },
  {
    key: 'jjkeller',
    name: 'JJ Keller',
    helpText:
      'Input the credentials you use to log into the JJ Keller Web App.',
  },
  {
    key: 'lytx',
    name: 'Lytx',
    helpText: (
      <ol>
        <li>
          In Lytx Developer Portal, go to Login at the top. Log in using Lytx
          account credentials.
        </li>
        <li>
          On the Dashboard, press 'Create API Credential'. This generates the
          API key.
        </li>
        <li>Copy the API key from the dashboard to the input here.</li>
      </ol>
    ),
  },
  {
    key: 'mapon',
    name: 'Mapon',
    helpText: 'Please request an API key from your Mapon representative.',
  },
  {
    key: 'matrack',
    name: 'Matrack',
    helpText: 'Please request an API key from your Matrack representative.',
  },
  {
    key: 'maven',
    name: 'Maven',
    helpText: 'Please request an API key from your Maven representative.',
  },
  {
    key: 'mobilefleet',
    name: 'Mobile Fleet Solutions',
    helpText:
      'Please input the username and password you use to log into the Portal.',
  },
  {
    key: 'nero',
    name: 'NERO',
    helpText:
      'Please input the username and password you use to log into the Portal.',
  },
  {
    key: 'netradyne',
    name: 'Netradyne',
    helpText:
      'Please request a Client ID and Client Secret from your Netradyne CSM. Then, input those credentials here.',
  },
  {
    key: 'nextraq',
    name: 'NexTraq',
    helpText:
      'Please input the username and password you use to log into the NexTraq app.',
  },
  {
    key: 'onestep',
    name: 'OneStepGPS',
    helpText: (
      <>
        <p>
          Please request an API key from{' '}
          <a
            href='mailto:integration@onestepgps.com'
            target='_blank'
            rel='noreferrer'
          >
            integration@onestepgps.com
          </a>
          . Then, input that key here.
        </p>
      </>
    ),
  },
  {
    key: 'routemate',
    name: 'RouteMate',
    helpText: 'Please request an API key from your RouteMate representative.',
  },
  {
    key: 'transflo',
    name: 'Transflo',
    helpText: '',
  },
  {
    key: 'optima',
    name: 'Optima',
    helpText: 'Please request an API key from your Optima representative.',
  },
  {
    key: 'orbcomm',
    name: 'Orbcomm',
    helpText:
      'Please input the username and password you use to log into the Portal.',
  },
  {
    key: 'payd',
    name: 'Payd',
    helpText: 'Please request an API key from your Payd representative.',
  },
  {
    key: 'peoplenet',
    name: 'PeopleNet',
    helpText:
      'Please input the username and password you use to log into the PeopleNet Fleet Manager.',
  },
  {
    key: 'phoenix',
    name: 'Phoenix',
    helpText:
      "Please input the company ID from your Phoenix profile. Include the word 'Company:', if provided.",
  },
  // {
  //   key: 'platformscience',
  //   name: 'Platform Science',
  //   helpText: (
  //     <p>
  //       Your API client ID and client secret can be generated in your Platform Science
  //       Fleet Portal by anyone with an Administrator role. Just navigate to the
  //       "Credentials" page and choose "Create Credentials" in the upper right
  //       corner.
  //     </p>
  //   ),
  // },
  {
    key: 'randmcnally',
    name: 'Rand McNally',
    helpText:
      'Input the credentials you use to log into the Rand McNally Web App.',
  },
  {
    key: 'rastrac',
    name: 'Rastrac',
    helpText: 'Input the credentials you use to log into the Rastrac Web App.',
  },
  {
    key: 'selectivedrive',
    name: 'Selective Drive Fleet',
    helpText:
      'Please request an API UUID and password from Selective Drive support. Then input them here.',
  },
  {
    key: 'surfsight',
    name: 'Surfsight',
    helpText:
      'Input the credentials you use to log into the Surfsight Web App.',
  },
  {
    key: 'swift',
    name: 'Swift',
    helpText: 'Please request an API Key Swift support. Then input it here.',
  },
  {
    key: 'switchboard',
    name: 'Switchboard',
    helpText: (
      <ol>
        <li>Login into your Switchboard account.</li>
        <li>Go to the "Settings" on the top right hand side of the window.</li>
        <li>At the bottom of the page, create a new API key and ID.</li>
        <li>
          Copy the API key and ID, as well as the username and password you use
          to sign into Switchboard here.
        </li>
      </ol>
    ),
  },
  {
    key: 'teletracnavman',
    name: 'Teletrac Navman',
    helpText:
      'Please request an API key from your Teletrac Navman representative.',
  },
  {
    key: 'telogis',
    name: 'Telogis',
    helpText:
      "Input the credentials you use to log into the Telogis Web App and your customer account code. The customer name of your VZ Connect Fleet account. If you're logged into the web platform, you can find your name in the in the URL before 'platform.telogis.com' or in the header area next to the 'Support' menu on the right.",
  },
  {
    key: 'tenna',
    name: 'Tenna',
    helpText: 'Input the credentials you use to log into the Tenna Dashboard.',
  },
  {
    key: 'think',
    name: 'Think',
    helpText: 'Please request an API key from your Think! representative.',
  },
  {
    key: 'traccar',
    name: 'Traccar',
    helpText:
      'Input the credentials you use to log into the Traccar Dashboard and request an API Key from your Traccar representative.',
  },
  {
    key: 'trimble',
    name: 'Trimble',
    helpText:
      'Please request an username and password from your Trimble representative.',
  },
  {
    key: 'truckx',
    name: 'TruckX',
    helpText: (
      <ol>
        <li>
          Login into the TruckX admin account.
          <ol>
            <li>
              Go to the "API Key Transfer" on the left pane of the window.
            </li>
            <li>
              When you click on API key transfer, there will be another
              dropdown. Click on "API Key transfer" in the drop-down menu. In
              this new window, please click on the "+" Share API key which is on
              the right top corner of this window.
            </li>
            <li>
              When you click on the +share API key, the pop-up window will open
              in which there would be an option to select API Partner, the truck
              Number, and the trailer number.
            </li>
            <li>Select the API partner - Axle Technologies</li>
            <li>
              Select the Truck number, Trailer number and the Driver name to be
              shared with the API partner. Click on the acknowledgment. Press
              the "Send API Key" to share the API key with the API partner.
            </li>
            <li>
              The selected API partner, number of trucks/ trailers can be viewed
              here, once shared. The API key is also visible on the admin
              account with the expiration date. The owner can revoke and extend
              the API sharing validity from this page only.
            </li>
          </ol>
        </li>
        <li>
          Input the API Key, your DOT Number, and your Provider token here.
        </li>
      </ol>
    ),
  },
  {
    key: 'tt',
    name: 'TT',
    helpText: (
      <ol>
        <li>Login into the TT ELD admin account.</li>
        <li>Click on Settings and then API Keys</li>
        <li>
          Click the 'Generate' button to initiate the API Key creation process.
        </li>
        <li>Input your DOT Number here.</li>
      </ol>
    ),
  },
  {
    key: 'tive',
    name: 'Tive',
    helpText: (
      <ol>
        <li>
          Follow the instructions to generate a Client ID and Client Secret at
          this URL:{' '}
          <a
            href='https://developers.tive.com/docs/authentication'
            rel='noreferrer'
            target='_blank'
          >
            https://developers.tive.com/docs/authentication
          </a>
        </li>
        <li>Input the generated Client ID and Client Secret here.</li>
      </ol>
    ),
  },
  {
    key: 'vistracks',
    name: 'VisTracks',
    helpText:
      'Input the credentials you use to log into the VisTracks Web App.',
  },
  {
    key: 'webfleet',
    name: 'Webfleet',
    helpText:
      'Input the credentials and company account name you use to log into the Webfleet App.',
  },
  {
    key: 'zonar',
    name: 'Zonar',
    helpText: (
      <>
        <ol>
          <li>
            Log into your Zonar dashboard.{' '}
            <ol>
              <li>Click "Manage" </li>
              <li>Go to "System Settings" </li>
              <li>Click "Users" </li>
              <li>
                Save the API username (NOT email address) of the Zonar OMI user
                account you will use to link your Zonar account.
              </li>
              <li>
                Ensure the API user has the "OMI" role & permissions are checked
                in the "settings" tab.
              </li>
            </ol>
          </li>
          <li>
            Fill out the link form with your Zonar API username, password, and
            Zonar Ground Traffic Control account number.
          </li>
          <li>Please note that the API username is case-sensitive.</li>
        </ol>
      </>
    ),
  },
  {
    key: 'zubie',
    name: 'Zubie',
    helpText: (
      <>
        <p>
          Zubie uses OAuth for secure access to your telematics data. You can
          connect by providing your Zubie admin credentials (i.e., your username
          and password). You have two options:
        </p>
        <ol>
          <li>
            Save your credentials (recommended):
            <ul>
              <li>Enter your Zubie admin username and password</li>
              <li>
                We’ll store them securely so we can reconnect automatically
              </li>
            </ul>
          </li>
          <li>
            Skip saving credentials:
            <ul>
              <li>Check “No thanks, I’ll re-authorize when needed”</li>
              <li>
                You’ll manually re-authorize if the connection expires or needs
                updating
              </li>
            </ul>
          </li>
        </ol>
        <p>
          In both cases, you’ll be redirected to Zubie’s OAuth flow to grant
          access to your account. If you need help finding your credentials, use
          the resources linked above.
        </p>
      </>
    ),
  },
  // { key: "trackensure", name: "TrackEnsure", helpText: 'lol good luck' },
];

export function getTSPNameByKey(key) {
  const foundTSP = tsps.find((x) => x.key === key);
  return foundTSP ? foundTSP.name : null;
}

export function getTSPHelpTextByKey(key) {
  const foundTSP = tsps.find((x) => x.key === key);
  return foundTSP ? foundTSP.helpText : null;
}
