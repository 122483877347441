import { useLocation } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';

export default function ErrorPage() {
  const { state } = useLocation();

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <img
          src='/images/truck.png'
          alt='truck'
          loading='lazy'
          width={'250vw'}
          height={'125vh'}
        />
      </Grid>
      <Grid
        container
        item
        justifyContent='center'
        alignItems='center'
        direction={'column'}
      >
        <Grid item>
          <Typography variant='h5' align={'center'}>
            <b>
              {state && state.errorMsg
                ? state.errorMsg
                : 'Something went wrong'}
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='caption' align={'center'}>
            {state && state.errorCaption
              ? state.errorCaption
              : 'Our team has been notified and is working on the issue.'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
