import { useEffect } from 'react';
import { TextFieldElement, useFormContext } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';

type UserPassLoginProps = {
  disabled?: boolean;
  onUsernameChange?: (value: string) => void;
  onPasswordChange?: (value: string) => void;
  usernameLabel?: string;
  passwordLabel?: string;
};

export default function UserPassLogin({
  disabled = false,
  onUsernameChange,
  onPasswordChange,
  usernameLabel = 'Admin Username',
  passwordLabel = 'Admin Password',
}: UserPassLoginProps) {
  const { unregister, resetField, register } = useFormContext();

  // Handle field registration and unregistration based on disabled state
  useEffect(() => {
    if (disabled) {
      // First unregister to remove from validation
      unregister(['username', 'password']);

      // Then reset fields to clear any error states
      resetField('username', {
        defaultValue: '',
        keepError: false,
        keepDirty: false,
        keepTouched: false,
      });
      resetField('password', {
        defaultValue: '',
        keepError: false,
        keepDirty: false,
        keepTouched: false,
      });
    } else {
      // Re-register fields when enabled
      register('username', { required: true });
      register('password', { required: true });
    }
  }, [disabled, unregister, resetField, register]);

  return (
    <>
      <OnboardGridItem>
        <TextFieldElement
          label={usernameLabel}
          type={'text'}
          name={'username'}
          required={!disabled}
          disabled={disabled}
          onChange={(e) => onUsernameChange?.(e.target.value)}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label={passwordLabel}
          type={'password'}
          name={'password'}
          required={!disabled}
          disabled={disabled}
          onChange={(e) => onPasswordChange?.(e.target.value)}
        />
      </OnboardGridItem>
    </>
  );
}
