import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';
import UserPassLogin from './UserPass';

export default function ZonarLogin() {
  return (
    <>
      <UserPassLogin
        usernameLabel='API Username (with OMI role)'
        passwordLabel='Password'
      />
      <OnboardGridItem>
        <TextFieldElement
          label='Customer GTC Code'
          type={'text'}
          name={'authorizationcode'}
          required
        />
      </OnboardGridItem>
    </>
  );
}
