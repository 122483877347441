import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';

export default function ClientIDClientSecretLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement label='Client ID' type={'text'} name={'database'} />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='Client Secret'
          type={'text'}
          name={'authorizationcode'}
        />
      </OnboardGridItem>
    </>
  );
}
