import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Chip, Divider, Grid, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import { PermissionsContext } from '../PermissionsContext';
import { ButtonWithText } from '../components/ButtonWithText';
import { getTSPNameByKey } from '../tsps';
import { checkValid } from '../utils/checkValid';

export default function UpdatePermissions() {
  const navigate = useNavigate();
  const [cancelOnboarding, setCancelOnboarding] = useState(false);
  const { search } = useLocation();
  const { token, TSP, setTSP, existingTSPs, setApproved, setUpdatingTSP } =
    useContext(PermissionsContext);

  const onCancel = () => {
    setCancelOnboarding(true);
  };

  const onAddNewTSP = () => {
    setApproved(true);
    if (TSP) {
      navigate({
        pathname: `/onboard`,
        search: search,
      });
    } else {
      navigate('/selectTSP');
    }
  };

  useEffect(() => {
    if (!checkValid(token)) {
      navigate('/error', {
        state: {
          errorMsg: 'Onboarding link is invalid.',
          errorCaption: 'Please try to onboard again.',
        },
      });
    }
  });

  return cancelOnboarding ? (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h4' textAlign={'center'}>
        Fleet onboarding canceled. You may now close this page.
      </Typography>
    </Grid>
  ) : (
    <Grid>
      <Grid padding={2}>
        <Typography variant='h5' textAlign={'center'}>
          You have already connected {existingTSPs.length}{' '}
          {existingTSPs.length > 1 ? 'devices' : 'device'}.
        </Typography>
      </Grid>
      <Grid container>
        <Grid width={'100%'} mb={2}>
          <Divider>
            <Chip
              label='UPDATE'
              color='primary'
              sx={{
                '&.MuiChip-colorPrimary': {
                  backgroundColor: red[50],
                  color: 'black',
                  fontWeight: 500,
                },
              }}
            />
          </Divider>
        </Grid>
        {existingTSPs.map((key: string) => (
          <Grid
            item
            key={key}
            xs={existingTSPs.length > 1 ? 6 : 12}
            onClick={() => {
              setTSP(key);
              setApproved(true);
              setUpdatingTSP(true);
              navigate({
                pathname: `/onboard`,
                search: search,
              });
            }}
            sx={{
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#fafafa',
              },
            }}
          >
            <Grid
              container
              alignItems={'center'}
              direction='column'
              padding={0.5}
              sx={{ minHeight: '1vh' }}
            >
              <Grid item>
                <img
                  src={`/images/${getTSPNameByKey(
                    key
                  )}.png?w=248&fit=crop&auto=format`}
                  alt={key}
                  loading='lazy'
                  width={'45vh'}
                  height={'45vh'}
                />
              </Grid>
              <Grid item>
                <Typography textAlign={'center'}>
                  {getTSPNameByKey(key)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid width={'100%'} padding={2}>
          <Divider>
            <Chip
              label='OR'
              color='primary'
              sx={{
                '&.MuiChip-colorPrimary': {
                  backgroundColor: red[50],
                  color: 'black',
                  fontWeight: 500,
                },
              }}
            />
          </Divider>
        </Grid>
        <Grid item width={'100%'}>
          <ButtonWithText onClick={onAddNewTSP}>
            {TSP ? `Add new ${TSP} connection` : 'Add New TSP'}
          </ButtonWithText>
        </Grid>
        <Grid item mt={6}>
          <Button variant='text' color='error' onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
