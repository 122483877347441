import { TextFieldElement } from 'react-hook-form-mui';

import { OnboardGridItem } from '../OnboardGridItem';
import UserPassLogin from './UserPass';

export default function IsaacLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement
          label='Authorization Code'
          type={'text'}
          name={'authorizationcode'}
          required
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='Fleet Name (optional)'
          type={'text'}
          name={'carriername'}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label='DOT Number (optional)'
          type={'number'}
          name={'dotnumber'}
        />
      </OnboardGridItem>
    </>
  );
}
