import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';

import * as amplitude from '@amplitude/analytics-browser';

import { PermissionsContext } from '../PermissionsContext';
import { AuthRouter } from '../components/AuthRouter';
import { ButtonExitLink, LinkPage } from '../components/ButtonExitLink';
import { ButtonWithText } from '../components/ButtonWithText';
import APIKeyLogin from '../components/loginpages/APIKeyLogin';
import AscendLogin from '../components/loginpages/Ascend';
import ClientIDClientSecretLogin from '../components/loginpages/ClientIDClientSecretLogin';
import DriverTechLogin from '../components/loginpages/DriverTech';
import EzLogzLogin from '../components/loginpages/EzLogz';
import FleetCompleteLogin from '../components/loginpages/FleetComplete';
import FleetUpLogin from '../components/loginpages/FleetUp';
import GeotabLogin from '../components/loginpages/Geotab';
import HOSConnectLogin from '../components/loginpages/HOSConnect';
import IsaacLogin from '../components/loginpages/Isaac';
import LytxLogin from '../components/loginpages/Lytx';
import MotiveLogin from '../components/loginpages/Motive';
import SamsaraLogin from '../components/loginpages/Samsara';
import SelectiveDrive from '../components/loginpages/SelectiveDrive';
import SwitchboardLogin from '../components/loginpages/Switchboard';
import TTLogin from '../components/loginpages/TT';
import TelogisLogin from '../components/loginpages/Telogis';
import TraccarLogin from '../components/loginpages/Traccar';
import TruckXLogin from '../components/loginpages/TruckX';
import UserPassLogin from '../components/loginpages/UserPass';
import VerizonLogin from '../components/loginpages/Verizon';
import WebfleetLogin from '../components/loginpages/Webfleet';
import ZonarLogin from '../components/loginpages/Zonar';
import ZubieLogin from '../components/loginpages/Zubie';
import { getTSPHelpTextByKey, getTSPNameByKey } from '../tsps';
import { LoginFormData } from '../types/LoginFormData';
import { checkValid } from '../utils/checkValid';
import { motiveRedirectURI } from '../utils/redirect';

const helpModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #D3D3D3',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export default function Onboard() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [disableButton, setDisableButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const {
    approved,
    fleetCode,
    magicLink,
    motiveClientID,
    samsaraClientID,
    zubieClientID,
    serviceID,
    token,
    TSP,
    updatingTSP,
    serviceName,
    redirectURIError,
    redirectURISuccess,
    setOnboarded,
    setRedirectURI,
    setTSP,
  } = useContext(PermissionsContext);

  // Validate token and approval on mount.
  useEffect(() => {
    if (!checkValid(token)) {
      navigate('/error', {
        state: {
          errorMsg: 'Onboarding link is invalid.',
          errorCaption: 'Please try to onboard again.',
        },
      });
    } else if (!approved) {
      navigate({ pathname: '/', search });
    }
  }, [token, approved, navigate, search]);

  // Create a string that encodes important state for the OAuth flow.
  const linkState = `${token},${updatingTSP},${magicLink},${serviceID},${fleetCode}`;

  // OAuth redirect handlers.
  const handleMotiveRedirect = useCallback(() => {
    if (motiveClientID) {
      let scopes;
      if (serviceName.includes('AtoB')) {
        scopes =
          'companies.read+users.read+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+vehicles.read+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+assets.read+locations.asset_locations+fuel_purchases.manage+ifta_reports.summary';
      } else {
        scopes =
          'eld_devices.read+fault_codes.read+driver_performance_events.read+speeding_events.read+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+ifta_reports.trips+ifta_reports.summary+utilization.driver_utilization+utilization.vehicle_utilization+utilization.idle_events+companies.read+locations.asset_locations+driving_periods.read+driver_details.read+collision_report.read+motive_card_transactions.read+users.manage+vehicles.manage+messages.manage+fuel_purchases.manage+inspection_reports.read+documents.read+assets.manage+hos_logs.manage+motive_card_cards.read';
      }
      const redirectURI = motiveRedirectURI(linkState);
      window.location.replace(
        `https://api.gomotive.com/oauth/authorize?client_id=${motiveClientID}&response_type=code&scope=${scopes}&redirect_uri=${redirectURI}`
      );
    } else {
      setShowError(true);
      setErrorText(
        'No Motive app is associated with this account - please contact Axle support'
      );
    }
  }, [motiveClientID, serviceName, linkState]);

  const handleSamsaraRedirect = useCallback(() => {
    if (samsaraClientID) {
      window.location.replace(
        `https://api.samsara.com/oauth2/authorize?client_id=${samsaraClientID}&response_type=code&state=${linkState}`
      );
    } else {
      setShowError(true);
      setErrorText(
        'No Samsara app is associated with this account - please contact Axle support'
      );
    }
  }, [samsaraClientID, linkState]);

  const handleZubieRedirect = useCallback(() => {
    if (zubieClientID) {
      window.location.replace(
        `https://login.zubiecar.com/authorize?client_id=${zubieClientID}&response_type=code&state=${linkState}`
      );
    } else {
      setShowError(true);
      setErrorText(
        'No Zubie app is associated with this account - please contact Axle support'
      );
    }
  }, [zubieClientID, linkState]);

  // Track help modal events.
  useEffect(() => {
    if (helpModalOpen) {
      amplitude.track('Help Modal Opened', { tsp: TSP, serviceName });
    }
  }, [helpModalOpen, TSP, serviceName]);

  // Handle back button click.
  const handleBackClick = useCallback(() => {
    amplitude.track('Onboarding Back Button Clicked', {
      tsp: TSP,
      serviceName,
    });
    setTSP(null);
    navigate(`/selectTSP?token=${token}`);
  }, [TSP, serviceName, setTSP, token, navigate]);

  // Mapping of TSP keys to their corresponding login components.
  const TSPLoginMapping: Record<string, () => JSX.Element> = useMemo(
    () => ({
      // Components that use APIKeyLogin.
      ada: () => <APIKeyLogin />,
      apollo: () => <APIKeyLogin />,
      blueinktech: () => <APIKeyLogin />,
      bigroad: () => <APIKeyLogin />,
      budgetgps: () => <APIKeyLogin />,
      eldmandate: () => <APIKeyLogin />,
      fleethunt: () => <APIKeyLogin />,
      gpstab: () => <APIKeyLogin />,
      mapon: () => <APIKeyLogin />,
      matrack: () => <APIKeyLogin />,
      maven: () => <APIKeyLogin />,
      mobilefleet: () => <UserPassLogin />,
      onestep: () => <APIKeyLogin />,
      optima: () => <APIKeyLogin />,
      routemate: () => <APIKeyLogin />,
      eroad: () => <APIKeyLogin />,
      swift: () => <APIKeyLogin />,
      hcss: () => <APIKeyLogin />,
      netradyne: () => <APIKeyLogin />,
      tive: () => <APIKeyLogin />,
      think: () => <APIKeyLogin />,
      payd: () => <APIKeyLogin />,
      fm: () => <APIKeyLogin />,
      hosconnect: () => <APIKeyLogin />,
      hutch: () => <APIKeyLogin />,
      teletracnavman: () => <APIKeyLogin />,
      // Components using ClientIDClientSecretLogin.
      truckspy: () => <ClientIDClientSecretLogin />,
      platformscience: () => <ClientIDClientSecretLogin />,
      // Other login components.
      ascend: () => <AscendLogin />,
      drivertech: () => <DriverTechLogin />,
      // Note: Fixed component name here: use EzLogzLogin (capital L) instead of EzlogzLogin.
      ezlogz: () => <EzLogzLogin />,
      fleetcomplete: () => <FleetCompleteLogin />,
      fleetup: () => <FleetUpLogin />,
      geotab: () => <GeotabLogin />,
      transflo: () => <GeotabLogin />,
      phoenix: () => <HOSConnectLogin />,
      isaac: () => <IsaacLogin />,
      lytx: () => <LytxLogin />,
      selectivedrive: () => <SelectiveDrive />,
      switchboard: () => <SwitchboardLogin />,
      telogis: () => <TelogisLogin />,
      traccar: () => <TraccarLogin />,
      trimble: () => <UserPassLogin />,
      truckx: () => <TruckXLogin />,
      tt: () => <TTLogin />,
      verizon: () => <VerizonLogin />,
      webfleet: () => <WebfleetLogin />,
      zonar: () => <ZonarLogin />,
      // OAuth TSPs.
      samsara: () => (
        <SamsaraLogin
          onDirectConnect={handleSamsaraRedirect}
          tsp={TSP}
          serviceName={serviceName}
          onValidityChange={setIsFormValid}
        />
      ),
      zubie: () => (
        <ZubieLogin
          onDirectConnect={handleZubieRedirect}
          tsp={TSP}
          serviceName={serviceName}
          onValidityChange={setIsFormValid}
        />
      ),
      motive: () => (
        <MotiveLogin
          onDirectConnect={handleMotiveRedirect}
          tsp={TSP}
          serviceName={serviceName}
          onValidityChange={setIsFormValid}
        />
      ),
    }),
    [
      TSP,
      serviceName,
      setIsFormValid,
      handleSamsaraRedirect,
      handleZubieRedirect,
      handleMotiveRedirect,
    ]
  );

  // Get the login component for the current TSP.
  const getTSPLogin = useCallback(
    (tsp: string): JSX.Element => {
      if (Object.prototype.hasOwnProperty.call(TSPLoginMapping, tsp)) {
        return TSPLoginMapping[tsp]();
      }
      return getTSPNameByKey(tsp) ? (
        <UserPassLogin />
      ) : (
        <Navigate
          to='/error'
          state={{
            errorMsg: 'Onboarding link is invalid.',
            errorCaption: 'Please try to onboard again.',
          }}
        />
      );
    },
    [TSPLoginMapping]
  );

  // Handle form submission via AuthRouter.
  const handleFormSubmit = async (formData: LoginFormData) => {
    setDisableButton(true);
    setShowError(false);

    // Ensure skipCredentials is a boolean.
    const data: LoginFormData = {
      ...formData,
      skipCredentials: !!formData.skipCredentials,
    };

    const authRouter = new AuthRouter({
      TSP,
      serviceName,
      token,
      fleetCode,
      updatingTSP,
      serviceID,
      magicLink,
      redirectURISuccess,
      clientIDs: { motiveClientID, samsaraClientID, zubieClientID },
      onSuccess: () => {
        setOnboarded(true);
        navigate('/confirmation');
      },
      onError: (message: string) => {
        setShowError(true);
        setErrorText(message);
        setDisableButton(false);
      },
      setRedirectURI,
    });

    try {
      await authRouter.handleFormSubmit(data);
    } catch {
      setDisableButton(false);
    }
  };

  const TSPName = getTSPNameByKey(TSP);
  const TSPHelpText = getTSPHelpTextByKey(TSP);

  return (
    <FormContainer
      defaultValues={{
        username: '',
        password: '',
        database: '',
        apikey: '',
        authorizationcode: '',
        carriername: '',
        FTPUsername: '',
        FTPPassword: '',
        dotnumber: 0,
        skipCredentials: false,
      }}
      onSuccess={handleFormSubmit}
      FormProps={{ autoComplete: 'off' }}
    >
      <Grid
        container
        sx={{ '& .MuiTextField-root': { width: '100%' }, height: '70vh' }}
        direction='column'
        justifyContent='center'
        alignItems='center'
        maxWidth='100%'
        display='block'
        overflow='scroll'
      >
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          maxWidth='100%'
        >
          <Grid item xs={4}>
            <Button
              sx={{
                minWidth: '40px',
                color: red[500],
                '&:hover': { backgroundColor: red[100] },
              }}
              onClick={handleBackClick}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={4}
            mb={2}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item>
              <Avatar
                src={`/images/${TSPName}.png`}
                variant='square'
                sx={{ width: 100, height: 100 }}
              />
            </Grid>
          </Grid>
          {redirectURIError && (
            <Grid item xs={4} display='flex' justifyContent='right'>
              <ButtonExitLink
                token={token}
                redirectURIError={redirectURIError}
                serviceName={serviceName}
                pageOnExit={LinkPage.OnboardingCreds}
                selectedTSP={TSP}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mb={1}>
          <Typography variant='h5' align='center'>
            Log into your {TSPName} account
          </Typography>
          <Typography variant='body2' align='center'>
            By providing your {TSPName} credentials to Axle, you're enabling
            Axle to retrieve your telematics data.
          </Typography>
        </Grid>
        <Grid item width='100%' mt={1}>
          {showError && <Alert severity='error'>{errorText}</Alert>}
        </Grid>
        {getTSPLogin(TSP)}
        <Grid item width='100%'>
          <ButtonWithText
            type='submit'
            disabled={
              TSP === 'motive' || TSP === 'samsara' || TSP === 'zubie'
                ? !isFormValid || disableButton
                : disableButton
            }
          >
            {disableButton ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              'Login'
            )}
          </ButtonWithText>
        </Grid>
        <Grid item width='100%'>
          <Button
            sx={{
              color: 'black',
              margin: '0 auto',
              display: 'flex',
              width: '100%',
              textTransform: 'none',
            }}
            onClick={() => setHelpModalOpen(true)}
          >
            Need help finding your credentials?
          </Button>
        </Grid>
        <Modal
          open={helpModalOpen}
          onClose={() => setHelpModalOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={helpModalStyle}>
            <IconButton
              aria-label='close'
              sx={{ position: 'fixed', top: 0, right: 0, zIndex: 2000 }}
              onClick={() => setHelpModalOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Need help finding your credentials?
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              {TSPHelpText}
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </FormContainer>
  );
}
